import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import deepEquals from "./deepEquals"
import useDebounce from "./useDebounce"

export interface UseAutoSaveOptions {
  /**
   * How many milliseconds to wait before saving. If value changes within this time, the timer resets.
   */
  debounceDelay?: number

  /**
   * Whether to save the value when the hook is unmounted
   */
  saveOnUnmount?: boolean

  /**
   * The callback to be called when there is an error saving the value
   * @param error the error that occurred
   */
  onError?: (error: unknown) => void
}

/**
 * Hook to handle autosave. Triggers save when:
 *   - there are changes to draft after debounceDelay
 *   - the component is unmounted
 *
 * @param original - the data returned by the server, usually from a query. It can be undefined (when the query has not initialised yet).
 * @param isFetching - whether the data is still being fetched, this is used so that we know when to sync original with the new server changes.
 * @param onSave - the function to call to save the value, usually you would call a mutation. This should only be called when there are changes.
 * @returns an object with the latest draft value and a function to set the draft value which causes a save after debounceDelay
 *
 * @example
 * const { data, isFetching } = useExampleQuery()
 * const { draft, setDraft } = useAutoSave(data, isFetching, (draft) => useExampleMutation(draft))
 *
 * const handleChange = (e) => {
 *   const newValue = ...
 *   setDraft(newValue)
 * }
 */
export default function useAutoSave<T extends object>(original: T | undefined, isFetching: boolean, onSave: (draft: T) => void, { debounceDelay = 5000, onError }: UseAutoSaveOptions = {}) {
  const initialRender = useRef(true) // so we don't save on mount
  const originalRef = useRef(original) // so that we can compare if there are changes
  const onSaveRef = useRef(onSave) // so that we don't trigger the useEffect that saves after a debounce when onSave changes
  const draftRef = useRef(original) // so that we can update draft without triggering a save
  const [draft, setDraft] = useState(draftRef.current) // so we can trigger the debounce
  const debouncedDraft = useDebounce(draft, debounceDelay)

  const [returnedDraft, setReturnedDraft] = useState(draftRef.current) // so that the component can react to changes in the draft done by itself or by the hook
  const setDraftMemoized = useCallback(
    (value: T) => {
      draftRef.current = value
      setDraft(value)
      setReturnedDraft(value)
    },
    [setDraft, setReturnedDraft]
  )

  // draft is only changed by the component so use returnedDraft can be changed when original changes
  const hasChanges = useMemo(() => !deepEquals(originalRef.current, returnedDraft), [original, returnedDraft])

  // Save after debounceDelay
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    if (debouncedDraft === undefined || deepEquals(originalRef.current, debouncedDraft)) return

    try {
      onSaveRef.current(debouncedDraft)
    } catch (err) {
      onError?.(err)
    }
  }, [debouncedDraft])

  useEffect(() => {
    onSaveRef.current = onSave
  }, [onSave])

  // Once state has been fetched/refetched from the server, update draft to match up to date state
  useEffect(() => {
    originalRef.current = original
    if (!isFetching) {
      // don't call setDraft as that triggers the debounce
      draftRef.current = original
      setReturnedDraft(original)
    }
  }, [isFetching, original, setReturnedDraft])

  // Save on unmount
  useEffect(() => {
    return () => {
      try {
        if (draftRef.current !== undefined && !deepEquals(originalRef.current, draftRef.current)) onSaveRef.current(draftRef.current)
      } catch (err) {
        onError?.(err)
      }
    }
  }, [])

  return {
    draft: returnedDraft,
    hasChanges: hasChanges,
    setDraft: setDraftMemoized
  }
}
