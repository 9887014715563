import { SelectableItemProps } from "@ggl/components"
import { CardholderField } from "../../common/types/api.cardholder.type"
import { PDFItem } from "../../common/types/api.personalDataField.type"
import { Results } from "../../common/types/api.search.type"
import { CardholderSearchColumn } from "../../common/types/api.settings.type"
import { isCardholderSearchFieldColumn, isCardholderSearchPdfColumn } from "../../common/utils/api.settings.utils"
import unique from "../../common/utils/unique"
import { GetCardholdersWithThumbnailsArgs } from "../../store/api.slice"
import { CardholderSimpleSearchFilter } from "./cardholderSimpleSearch.type"

export const COLUMN_NAMES = {
  firstName: "First Name",
  lastName: "Last Name",
  description: "Description",
  division: "Division",
  lastZone: "Last Zone",
  authorised: "Authorised",
  cardNumbers: "Card Numbers"
} as const

export const FILTER_NAMES: Record<CardholderSimpleSearchFilter, string> = {
  name: "Name",
  description: "Description",
  cardNumbers: "Card Number(s)"
} as const

export const UNKNOWN_PDF_NAME = "Personal Data Field"

/**
 * We always want to fetch these fields.
 */
export const ALWAYS_FETCH_FIELDS: CardholderField[] = [
  "id", // for thumbnails
  "firstName", // for avatar
  "lastName" // for avatar
]

export const INVALID_ID = "-1" as const

export const getCardholderFields = (searchColumns: CardholderSearchColumn[]) => {
  const fields: Set<CardholderField> = new Set(ALWAYS_FETCH_FIELDS)
  for (const column of searchColumns) {
    if (isCardholderSearchFieldColumn(column)) {
      switch (column.name) {
        case "lastZone":
          fields.add("lastSuccessfulAccessZone")
          fields.add("lastSuccessfulAccessTime")
          break
        case "cardNumbers":
          fields.add("cards.number")
          break
        default:
          fields.add(column.name as CardholderField)
          break
      }
    } else if (isCardholderSearchPdfColumn(column)) {
      fields.add(`pdf_${column.pdfId}`)
    }
  }

  return [...fields]
}

export const getSearchFiltersSelectableItemProps = (columns: CardholderSearchColumn[], pdfDefinitions?: Results<PDFItem>) => {
  // The value matches that of the query param used to filter by that field
  const filters = [] as SelectableItemProps[]

  // Fields go first - sorted on a fixed order
  for (const column of columns.filter(isCardholderSearchFieldColumn)) {
    switch (column.name) {
      case "authorised":
        break // not supported
      case "firstName":
      case "lastName":
        filters.push({
          text: FILTER_NAMES.name,
          value: "name" as CardholderSimpleSearchFilter
        })
        break
      case "cardNumbers":
        filters.push({
          text: FILTER_NAMES["cardNumbers"],
          value: "cardNumbers" as CardholderSimpleSearchFilter
        })
        break
      default:
        filters.push({
          text: FILTER_NAMES[column.name as CardholderSimpleSearchFilter],
          value: column.name
        })
        break
    }
  }

  // exclude image and date pdfs
  const pdfFilters = columns
    .filter(isCardholderSearchPdfColumn)
    .filter((c) => {
      const def = pdfDefinitions?.results.find((pdf) => pdf.id === c.pdfId.toString())
      return def && def.type !== "image" && def.type !== "date"
    })
    .map((c) => ({
      text: getPdfColumnNameOrDefault(c.pdfId, pdfDefinitions),
      value: `pdf_${c.pdfId}`
    }))
  filters.push(...pdfFilters)

  const sortedFilters = unique(filters, (f) => f.value)
    .filter((f) => f.text !== undefined)
    .sort((a, b) => a.text!.localeCompare(b.text!))

  return sortedFilters
}

/**
 * The args for useGetCardholders query
 */
export const getSearchArgs = (
  getCardholdersUrl: string | undefined,
  getCardholderThumbnailsUrl: string | undefined,
  query: string,
  filter: CardholderSimpleSearchFilter | undefined,
  sort: string,
  top: number,
  searchColumns: CardholderSearchColumn[]
) => {
  const args: GetCardholdersWithThumbnailsArgs = {
    getCardholdersUrl,
    getCardholderThumbnailsUrl,
    sort: sort,
    top: top,
    fields: getCardholderFields(searchColumns)
  }

  switch (filter) {
    case "cardNumbers":
      args["cards.number"] = query
      break
    case undefined:
      break
    case "name":
    case "description":
    default:
      args[filter] = query
      break
  }

  return args
}

export const getPdfColumnNameOrDefault = (pdfId: number, pdfDefinitions?: Results<PDFItem>) => {
  return pdfDefinitions?.results.find((pdf) => pdf.id === pdfId.toString())?.name ?? `${UNKNOWN_PDF_NAME} ${pdfId}`
}
